<script>
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";
import Layout from "../../layouts/main";

/**
 * Dashboard component
 */
export default {
	components: {
		Layout,
	},
	mounted() {
		this.setTodayDate();

		const user = JSON.parse(localStorage.getItem('user'));
		if (user && user.name) {
			this.profileName = user.name;
		}

		this.getCount();
	},
	computed: {
		tutorTotal() {
			return this.num_of_tutors;
		},
		assignmentTotal() {
			return this.num_of_assignments;
		},
		totalOpen() {
			return this.num_of_open_assignments;
		},
		totalClosed() {
			return this.num_of_closed_assignments;
		},
		requestTotal() {
			return this.num_of_requests;
		},
	},
	methods: {
		setTodayDate() {
			const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			const monthsOfYear = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

			const now = new Date();
			const dayOfWeek = daysOfWeek[now.getDay()];
			const month = monthsOfYear[now.getMonth()];
			const dayOfMonth = now.getDate();
			const suffix = this.getDateSuffix(dayOfMonth);

			this.today = `${dayOfWeek}, ${month} ${dayOfMonth}${suffix}`;
		},
		getDateSuffix(day) {
			if (day >= 11 && day <= 13) {
				return 'th';
			}
			switch (day % 10) {
				case 1: return 'st';
				case 2: return 'nd';
				case 3: return 'rd';
				default: return 'th';
			}
		},
		async getCount() {
            this.loading = true;
            let result = [];

            try {
                const item = await axios.get(`${process.env.VUE_APP_APIURL}/dashboard`, {
                    headers: authHeader(),
                });

                const result = await item.data;
                if (result['code'] === 'success') {
					if (result['data'].num_of_tutors) {
						this.num_of_tutors = result['data'].num_of_tutors;
					} 

					if (result['data'].num_of_assignments) {
						this.num_of_assignments = result['data'].num_of_assignments;
					}

					if (result['data'].num_of_open_assignments) {
						this.num_of_open_assignments = result['data'].num_of_open_assignments;
					}

					if (result['data'].num_of_closed_assignments) {
						this.num_of_closed_assignments = result['data'].num_of_closed_assignments;
					}

					if (result['data'].num_of_requests) {
						this.num_of_requests = result['data'].num_of_requests;
					}
                }
            } catch (e) {
                console.error(e);
            }

            this.loading = false;
            return result;
        },
	},
	data() {
		return {
			title: "Dashboard",
			items: [
				{
					text: "Dashboard"
				}
			],
			today: '',
			profileName: '',
			num_of_tutors: 0,
			num_of_assignments: 0,
			num_of_open_assignments: 0,
			num_of_closed_assignments: 0,
			num_of_requests: 0,
		};
	}
};
</script>

<template>
	<Layout>
		<div class="row">
			<div class="col-12">
				<p class="today_date">{{ today }}</p>
				<p class="greeting">Good day, {{ profileName }}.</p>
			</div>
		</div>

		<div class="dashboard__cards">
			<router-link :to="{name: 'Assignment'}">
				<div class="dashboard__card">
					<img src="@/assets/images/icons/list-icon.png" height="36" alt="List" />
					<div>
						<p class="total_text">Total Assignments</p>
						<p class="total_number">{{ assignmentTotal }}</p>
					</div>
				</div>
			</router-link>

			<router-link :to="{name: 'Assignment'}">
				<div class="dashboard__card">
					<img src="@/assets/images/icons/open-assignment.png" height="36" alt="Icon" />
					<div>
						<p class="total_text">Open Assignments</p>
						<p class="total_number">{{ totalOpen }}</p>
					</div>
				</div>
			</router-link>

			<router-link :to="{name: 'Assignment'}">
				<div class="dashboard__card">
					<img src="@/assets/images/icons/closed-assignment.png" height="36" alt="Icon" />
					<div>
						<p class="total_text">Closed Assignments</p>
						<p class="total_number">{{ totalClosed }}</p>
					</div>
				</div>
			</router-link>

			<router-link :to="{name: 'Tutor'}">
				<div class="dashboard__card">
					<img src="@/assets/images/icons/user-icon.png" height="36" alt="User" />
					<div>
						<p class="total_text">Total Service Providers</p>
						<p class="total_number">{{ tutorTotal }}</p>
					</div>
				</div>
			</router-link>

			<router-link :to="{name: 'Parent Request'}">
				<div class="dashboard__card">
					<img src="@/assets/images/icons/list-icon.png" height="36" alt="List" />
					<div>
						<p class="total_text">Total Requests</p>
						<p class="total_number">{{ requestTotal }}</p>
					</div>
				</div>
			</router-link>
		</div>
	</Layout>
</template>